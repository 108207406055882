import React from 'react'
import classes from './QrCode.module.css';

const QrCode = () => {
  return (
    <div className={classes.ParentContainer}>
        <div className={classes.Link}>Instagram Link</div>
        <div className={classes.Link}>Website Link</div>
    </div>
  )
}

export default QrCode;
