import './App.css';
import { Route, Routes } from 'react-router-dom';
import QrCode from './pages/QrCode/QrCode';

function App() {
  return (
    <div>
      <Routes>
        <Route
          key="1"
          path="/qrcode"
          element={<QrCode/>}
        />
      </Routes>
    </div>
  );
}

export default App;
